<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
 
   <v-row>
    <v-col cols="12"> 
      <base-material-card
        icon="mdi-format-list-bulleted-type"
        title="Alerts"
        class="px-5 py-3"
      >
          <v-simple-table>
              <thead>
              <tr>
                  <th class="primary--text">
                  Details
                  </th>
                  <th class="text-right primary--text">
                  Count
                  </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>Change Order(s) Needing Approval</td>
                  <td class="text-right">
                      {{change_order_needing_approval}}
                  </td>
              </tr>
              <tr>
                  <td>Initial Home Cost</td>
                  <td class="text-right">
                      {{initial_home_cost}}
                  </td>
              </tr>
              </tbody>
          </v-simple-table>
      </base-material-card>
    </v-col>
   </v-row>
   <v-row>
    <v-col cols="12" md="6">
      <base-material-card
        icon="mdi-currency-usd"
        title="Page-2 Breakdown"
        class="px-5 py-3"
      >
        <v-data-table
                :headers="cb_headers"
                :items="cost_breakdown"
                disable-pagination
                :hide-default-footer="true"
                >
                <template v-slot:[`item.ModifiedDate`]="{ item }">
                  <span>{{ new Date(item.ModifiedDate).toLocaleString() }}</span>
                </template>
              </v-data-table>
      </base-material-card>
    </v-col>
    <v-col cols="12" md="6">
        <base-material-card
          icon="mdi-clipboard-flow-outline"
          title="Change Orders"
          class="px-5 py-3"
        >
          <v-data-table
            :headers="co_headers"
            :items="homeSpecificChangeOrders"
            >

            <template v-slot:[`item.change_order`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="view_change_order(item)"
                >
                  mdi-pencil
                </v-icon>

            <v-dialog
                v-model="dialog"
                max-width="50%"
                :retain-focus="false"
              >
              <v-form
              ref="form"
              v-model="valid"
              >
                <v-card>
                    <v-card-title>
                      <span class="card-title font-weight-light">Change Order</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                      
                          <v-row>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="selected_change_order.HomeOwnerName"
                                label="Homeowner Name"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="selected_change_order.Title"
                                label="Change Title"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <v-textarea
                                v-model="selected_change_order.HomeOwnerComment"
                                label="Homeowner comments"
                                :rules="commentRules"
                                disabled
                              ></v-textarea>
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <v-textarea
                                v-model="selected_change_order.StaffComment"
                                label="Additional Notes From Boulder staff (Attached to Email)"
                                :rules="commentRules"
                              ></v-textarea>
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                            <v-autocomplete
                              :items="vendors"
                              label="Vendor"
                              item-text="VendorName"
                              item-value="VendorId"
                              menu-props="auto"
                              class="px-3 py-3"
                              :rules="vendorRules"
                              v-model="selected_change_order.VendorId"
                            ></v-autocomplete>

                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="selected_change_order.Cost"
                                label="Cost"
                                type="number"
                                :counter="4"
                                :rules="costRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="approve"
                        :disabled="!valid"
                      >
                        Approve
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="decline"
                        :disabled="!valid"
                      >
                        Decline
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions>
                </v-card>
              </v-form>

            </v-dialog>
            </template>
          </v-data-table>
        </base-material-card>
    </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HomeownerDashboard',
  data () {
      return {

        //cost break down
        homeId : localStorage.getItem("homeid"),

        pageNumber : 2,
        cb_headers: [
            {
            text: 'Item',
            sortable: false,
            class:"primary--text",
            value: 'Item',
            },
            {
            text: 'Cost',
            value: 'Cost',
            sortable: false,
            class:"primary--text",
            },
            {
            text: 'Modified Date',
            value: 'ModifiedDate',
            sortable: false,
            class:"primary--text",
            },
        ],
        cost_breakdown: [],
       
       //alerts
        change_order_needing_approval: 0,
        initial_home_cost:0,
        //TODO
        homeowner_Email : localStorage.getItem("homeownerEmail"),

        //change order
        dialog: false,
        valid: false,
        //TODO
        userType:localStorage.getItem("user_type"),
        staff_email : localStorage.getItem("staff_email"),
        boulder_email : localStorage.getItem("boulder_email"),
        commentRules: [
          v => !!v || 'Comments are required',
          ],
        costRules: [
          v => !!v || 'Cost is required',
          v => (v<=9999) || 'Max 4 characters.',
          ],
        vendorRules: [
          v => !!v || 'Vendor is required'
          ],
        co_headers: [
            {
            text: 'Homeowner Name',
            sortable: false,
            class:"primary--text",
            value: 'HomeOwnerName',
            },
            { 
            text: 'Change Order Type', 
            value: 'Title', 
            sortable: false,
            class:"primary--text",
            },
            { 
            text: 'Status', 
            value: 'StatusId', 
            align: 'end',
            sortable: false,
            class:"primary--text",
            },
            { 
            text: 'Approve/Decline', 
            value: 'change_order', 
            align: 'end',
            sortable: false,
            class:"primary--text",
            }
        ],
        change_orders: [],
        homeSpecificChangeOrders: [],
        selected_change_order: {
            HomeOwnerId: localStorage.getItem("homeid"),
            HomeOwnerName: '',
            HomeownerEmail:'',
            VendorEmail:'',
            VendorId:'',
            OrderId:'',
            Title: '',
            HomeOwnerComment: '',
            StaffComment: '',
            Cost:''
        },
        default_change_order: {
            HomeOwnerId: localStorage.getItem("homeid"),
            HomeOwnerName: '',
            HomeownerEmail:'',
            VendorEmail:'',
            VendorId:'',
            OrderId:'',
            Title: '',
            HomeOwnerComment: '',
            StaffComment: '',
            Cost:''
        },
        vendors: []

      }
    },

    computed: {
    permissions(){
        return {
          scope: localStorage.getItem("permissions")
        }
      }
    },
    watch: {
      
    },
    created () {
      this.get_cost_breakdown();
      this.initializeChangeOrders();
      this.initializeVendors();
      this.getAlerts();
    },
    methods: {

      //cost breakdown
      get_cost_breakdown(){
        axios.get(process.env.VUE_APP_GET_COSTBREAKDOWN_URL,
        {
          params: {
            home_id: this.homeId,
            page_number: this.pageNumber
          }
        }
        )
        .then(response => {
          this.cost_breakdown = response.data
        })
        .catch(error => {
          console.log(error)
        })
        },

      //change orders
      initializeChangeOrders () {
        
        axios
        .get(process.env.VUE_APP_GET_CHANGEORDERS_URL)
        .then(response => {
          this.change_orders = response.data
          console.log("🚀 ~ file: HomeOwnerDashboard.vue ~ line 360 ~ initializeChangeOrders ~ this.change_orders", this.change_orders)
          this.change_orders.forEach((change_order) => {
            if(this.homeowner_Email === change_order.HomeownerEmail){
              this.homeSpecificChangeOrders.push(change_order)
              if(change_order.StatusId === 1){
                this.change_order_needing_approval++
              }
            }
          })
        })
        .catch(error => {
          console.log(error)
        })

      },

      initializeVendors () {
        
        axios
        .get(process.env.VUE_APP_GET_VENDORS_URL)
        .then(response => {
          this.vendors = response.data
          console.log("🚀 ~ file: HomeOwnerDashboard.vue ~ line 379 ~ initializeVendors ~ this.vendors", this.vendors)
        })
        .catch(error => {
          console.log(error)
        })

      },
      
      view_change_order (change_order) {
        this.selected_change_order = Object.assign({}, change_order)
        this.dialog = true
      },

      close () {
        this.selected_change_order = Object.assign({}, this.default_change_order)
        this.dialog = false
      },

      approve ()
      {
        var body = {
          "vendor_id":this.selected_change_order.VendorId,
          "owner_comment":this.selected_change_order.HomeOwnerComment,
          "staff_comment":this.selected_change_order.StaffComment,
          "cost":this.selected_change_order.Cost,
          "status":this.acceptStatus,
          "order_id":this.selected_change_order.OrderId
        }

        axios
        .post(process.env.VUE_APP_UPDATE_CHANGEORDER_URL,body)
        .then(() => {
          this.sendEmail([this.staff_email],this.$auth.user.email,"change_order_homeowner_approved_notification","random", this.selected_change_order.Title)
          this.selected_change_order = Object.assign({}, this.default_change_order)
        })
        .catch(error => {
          console.log(error)
        })

        this.initializeChangeOrders()
        this.dialog = false
      },

      decline()
      {
        var body = {
          "vendor_id":this.selected_change_order.VendorId,
          "owner_comment":this.selected_change_order.HomeOwnerComment,
          "staff_comment":this.selected_change_order.StaffComment,
          "cost":this.selected_change_order.Cost,
          "status":this.declineStatus,
          "order_id":this.selected_change_order.OrderId
        }

        axios
        .post(process.env.VUE_APP_UPDATE_CHANGEORDER_URL,body)
        .then(() => {
          var to = (this.userType === 'staff') ? [this.selected_change_order.HomeownerEmail]: [this.staff_email]
          this.sendEmail(to,this.boulder_email,this.declineEmailTemplate,"this is test variable")
          this.selected_change_order = Object.assign({}, this.default_change_order)
        })
        .catch(error => {
          console.log(error)
        })
        this.initializeChangeOrders()
        this.dialog = false
      },

      sendEmail(to,from,template,content){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(() => {
        })
        .catch(error => {
          console.log(error)
        })

      },

      getAlerts() {
          axios
          .get(process.env.VUE_APP_GET_DASHBOARD_ALERTS_URL,
          {
          params: {
              board: 'homeowner_dashboard',
              home_id: this.homeId,
              home_owner_id: this.homewnerId
          }
          })
          .then(response => {
            const {data} = response
            console.log("🚀 ~ file: HomeOwnerDashboard.vue ~ line 484 ~ getAlerts ~ data", data)
              if (data)
              {
                  var result = data
                  var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',

                  // These options are needed to round to whole numbers if that's what you want.
                  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
                  });
                  this.initial_home_cost = formatter.format(result['sum_hc'])
              }
          })
          .catch(error => {
          console.log(error)
          })
      },

    },
}
</script>


<style scoped>
.v-data-table table thead tr th {
    font-weight: 300;
    font-size: 17px;
    padding: 0 8px;
}
</style>